<template>
    <div v-if="isDataSourceCorrect"
        :key="id"
        class="p-relative"
    >

        <Toolbar :menu="menu" v-on:toolbar-button-click="onToolbarClick"  />
        <DocumentPanel :document-info="{}" />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>

        </v-tabs>
        
        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="id + item.FormId"
            >
                <template v-if="item.FormId == '0202160'">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <report-interaction-tab
                            :ref="item.FormId"
                            :raw-scheme="scheme"
                            :raw-data="data"
                            :is-view-mode="isViewMode"
                        />
                    </v-form>
                </template>
                <template v-else-if="!!item">
                    <component
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId"
                        :id="id"
                    />
                </template>
            </v-tab-item>
        </v-tabs-items>

    </div>    
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n'
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import { httpAPI } from "@/api/httpAPI";

    export default {
        name: "ReportInteractionCard",
        props: {
            queryId: {
                type: String,
                default: null
            },
            executionId: {
                type: String,
                default: null
            }
        },
        components: { 
            Toolbar,
            DocumentPanel,
        },
        computed: {
            ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
            isDataSourceCorrect() {
                return this.dataSource?.Data?.FormId === "1801020";
            },
            id() {
                return this.queryId + this.executionId;
            },
            menu() {
                let menu = [];
                
                menu = menu.concat(this.dataSource?.Data?.Menu ?? []);

                return menu;
            },
            pages() {
                let pages = 
                [
                    { Count: -1, FormId: "0202160", Name: i18n.t("Форма_отчета") }
                ];

                pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

                return pages;
            },
            scheme() {
                return this.dataSource?.Data?.Object?.Scheme ?? null;
            },
            data() {
                return this.dataSource?.Data?.Object?.Data ?? null;
            },
            isViewMode() {
                return this.dataSource?.Data?.Object?.ReadOnly ?? true;
            }
        },
        data() {
            return {
                tab: null
            }
        },
        methods: {
            ...mapActions('reportinteraction', ['fetchCurrentFillData']),
            ...mapActions(['setOverlayVisible']),
            async onToolbarClick(event, button) {
                if(button.Action == 'SendReportForm'){
                    let validate_result = this.$refs.form[0].validate();
                    if(!validate_result){
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                        return;
                    }
                }

                if (button.Action == "GenerateReport") {
                    this.GenerateReport(this.queryId, this.executionId);
                    return;
                }

                if (button.Action == "AcceptReportForm") {
                    this.AcceptReportForm(this.queryId, this.executionId);
                    return;
                }

                if (button.Action == "ReturnReportForm") {
                    this.ReturnReportForm(this.queryId, this.executionId);
                    return;
                }
                
                let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})

                if (actions.includes(button.Action))
                    await this.$store.dispatch(`actionsource/${button.Action}`, { event, button, queryId: this.queryId, executionId: this.executionId });
                else
                    this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
            },
            onTabClicked(formId) {
                if (formId === "0202160") return;

                let component = this.$refs[formId]?.[0];

                if (component)
                    if (typeof component.update === 'function')
                        component.update();
            },
            async GenerateReport(documentId, executionId) {
                
                let svod = localStorage.getItem('report-interaction:svod-mode') ?? false;
                let autoSvod = localStorage.getItem('report-interaction:auto-svod') ?? false;

                // формируем URL
                let url = `api/download/generatereport?documentId=${documentId}&svod=${svod}&executionId=${executionId}&autoSvod=${autoSvod}&mode=${process.env.NODE_ENV}`;

                if (process.env.NODE_ENV === "production") {
                    // этот механизм будет работать если хост VUE app и хост API одинаковый
                    url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "report.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                // для разработки
                else {
                    let response = await httpAPI({ url, method: 'GET', responseType: 'blob' });
                    
                    if (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'report.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            },
            async AcceptReportForm(documentId, executionId) {
                this.$notify.confirm(
                    i18n.t('Принять_отчет'),
                    async () => {
                        try
                        {
                            this.setOverlayVisible({ visible: true });

                            let response = await httpAPI({
                                url: `api/actions/acceptreportform?documentid=${documentId}&executionid=${executionId}`,
                                method: 'GET',
                            });

                            if (response.data.payload.result == "OK"){
                                this.$notify.success(response.data.payload.message);
                                this.$router.go(-1);
                            }
                            else
                                this.$notify.alert(response.data.payload.message);

                            this.setOverlayVisible({ visible: false });
                        }
                        catch (ex)
                        {
                            console.log(ex.message);
                        }
                    }
                );
            },
            async ReturnReportForm(documentId, executionId) {
                try {
                    let reason = await this.$store.dispatch(`dialogs/returnreason/open`);
                    if (reason){
                        try
                        {
                            this.setOverlayVisible({ visible: true });

                            let response = await httpAPI({
                                url: `api/actions/returnreportform`,
                                headers: { 'Content-Type': 'application/json' },
                                data: { Content : JSON.stringify( {documentId, executionId, reason}) },
                                method: 'POST',
                            });

                            if (response.data.payload.result == "OK") { 
                                this.$notify.success(response.data.payload.message);
                                this.$router.go(-1);
                            }
                            else
                                this.$notify.alert(response.data.payload.message);

                            this.setOverlayVisible({ visible: false });
                        }
                        catch (ex)
                        {
                            this.$notify.alert(ex.message);
                            this.setOverlayVisible({ visible: false });
                        }
                    }
                }
                catch (ex)
                {
                    console.log(ex.message);
                }
            },
        },
        async mounted() {    
            (async() => {
                while(typeof this.$refs.form === 'undefined')
                    await new Promise(resolve => setTimeout(resolve, 100));

                this.$refs?.form[0]?.validate();
            })();
        },
        async updated() {
            (async() => {
                while(typeof this.$refs.form === 'undefined')
                    await new Promise(resolve => setTimeout(resolve, 100));

                this.$refs?.form[0]?.validate();
            })(); 
        }
    }
</script>